import React from "react";
import Modal from "react-bootstrap/Modal";
import CloseButton from "../CloseButton/CloseButton";
import styles from "./VimeoModal.module.scss";
import ReactPlayer from "react-player/lazy";

export default function HowItWorksModal(props) {
  const {video} = props;
  return (
    <Modal
      {...props}
      className="video-modal"
      centered
      dialogClassName={styles.modalDialog}
      contentClassName={styles.modalContent}
    >
      <Modal.Header className={styles.header}>
        <div className={styles.closeButtonWrapper}>
          <CloseButton onClose={props.onHide} />
        </div>
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <ReactPlayer
          url={video}
          config={{
            vimeo: {
              playerOptions: {responsive: true},
            },
          }}
          width="100%"
          height="100%"
          playing={true}
          playsinline={true}
          autoPlay={true}
          controls={true}
        />
      </Modal.Body>
    </Modal>
  );
}
