import classNames from "classnames";
import Script from "next/script";
import {useState} from "react";
import {useRef} from "react";
import {Spinner} from "react-bootstrap";
import {Field, Form} from "react-final-form";
import ErrorMessage from "../LandingPageMarketingForm/ErrorMessage";
import FormMessageBanner from "../LandingPageMarketingForm/FormMessageBanner";
import {
  composeValidators,
  required,
  validateEmail,
  validatePostalCode,
} from "../Shared/Form/validators";
import styles from "./MailchimpForm.module.scss";

export default function MailchimpForm({disclosure, onSuccess}) {
  const [formError, setFormError] = useState("");
  const formRef = useRef(null);

  function handleOnSubmit() {
    setFormError("");

    return new Promise((resolve) => {
      const ajaxOptions = {
        url: "https://nxcr.us5.list-manage.com/subscribe/post-json?u=7fbd7a9c73e67d08051cd8784&id=62d1dd2eb2&f_id=00febce6f0&c=?",
        type: "GET",
        dataType: "json",
        contentType: "application/json; charset=utf-8",
        error: () => {
          setFormError("Something went wrong. Try again later.");
          resolve();
        },
        success: ({result, msg}) => {
          if (result === "error") {
            setFormError(msg);
          } else {
            onSuccess();
          }
          resolve();
        },
      };

      window.$mcj(formRef.current).ajaxSubmit(ajaxOptions);
    });
  }

  return (
    <>
      <Form
        initialValues={{
          b_7fbd7a9c73e67d08051cd8784_62d1dd2eb2: "",
          EMAIL: "",
          subscribe: "Subscribe",
          tags: "",
        }}
        onSubmit={handleOnSubmit}
        render={({handleSubmit, submitting}) => (
          <form onSubmit={handleSubmit} ref={formRef}>
            <div className={styles.formGroup}>
              <label className={styles.label} htmlFor="EMAIL">
                Email Address
              </label>
              <Field
                id="EMAIL"
                name="EMAIL"
                type="email"
                validate={composeValidators(required, validateEmail)}
              >
                {({input, meta}) => (
                  <>
                    <input
                      className={classNames("ignoreGlobal", styles.input)}
                      {...input}
                    />
                    <ErrorMessage meta={meta} />
                  </>
                )}
              </Field>
            </div>
            <div className={styles.formGroup}>
              <label className={styles.label} htmlFor="MMERGE6">
                Zip
              </label>
              <Field
                id="MMERGE6"
                name="MMERGE6"
                type="text"
                validate={composeValidators(required, validatePostalCode)}
              >
                {({input, meta}) => (
                  <>
                    <input
                      className={classNames("ignoreGlobal", styles.input)}
                      inputMode="numeric"
                      {...input}
                    />
                    <ErrorMessage meta={meta} />
                  </>
                )}
              </Field>
            </div>
            {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
            <div
              aria-hidden={true}
              style={{position: "absolute", left: "-5000px"}}
            >
              <Field
                component="input"
                id="b_7fbd7a9c73e67d08051cd8784_62d1dd2eb2"
                name="b_7fbd7a9c73e67d08051cd8784_62d1dd2eb2"
                tabIndex="-1"
                type="text"
              />
            </div>
            <div className={styles.disclosure}>{disclosure}</div>
            <div>
              <button
                className={styles.submitButton}
                disabled={submitting}
                type="submit"
              >
                Submit
                {submitting && (
                  <Spinner
                    animation="border"
                    aria-hidden="true"
                    as="span"
                    className="ms-2"
                    role="status"
                    size="sm"
                  />
                )}
              </button>
            </div>
            {formError && (
              <FormMessageBanner
                alertType="error"
                message={formError}
                onClose={() => setFormError("")}
                show={!!formError}
              />
            )}
          </form>
        )}
      />
      <Script
        strategy="afterInteractive"
        src="https://s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"
        type="text/javascript"
        onLoad={() => {
          (function ($) {
            window.fnames = new Array();
            window.ftypes = new Array();
            fnames[0] = "EMAIL";
            ftypes[0] = "email";
            fnames[3] = "ADDRESS";
            ftypes[3] = "address";
            fnames[4] = "PHONE";
            ftypes[4] = "phone";
            fnames[1] = "ROLE";
            ftypes[1] = "dropdown";
            fnames[2] = "FIRST_NAME";
            ftypes[2] = "text";
            fnames[5] = "LAST_NAME";
            ftypes[5] = "text";
            fnames[6] = "MMERGE6";
            ftypes[6] = "zip";
          })(jQuery);
          window.$mcj = jQuery.noConflict(true);
        }}
      />
    </>
  );
}
