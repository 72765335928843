import Modal from "react-bootstrap/Modal";
import useSWRImmutable from "swr/immutable";
import {butter} from "../../lib/butter-api";
import useIsMobileDevice from "../hooks/useIsMobileDevice";
import ExpandingToNewStatesForm from "./ExpandingToNewStatesForm";
import styles from "./ExpandingToNewStatesModal.module.scss";

function butterPageFetcher(page_slug) {
  return butter.page.retrieve("*", page_slug).then(({data}) => data?.data);
}

export default function ExpandingToNewStatesModal({show, onHide}) {
  const {data} = useSWRImmutable(
    "expanding-to-new-states-web-view",
    butterPageFetcher
  );
  const isMobileDevice = useIsMobileDevice();

  return (
    <Modal
      fullscreen={isMobileDevice}
      contentClassName={styles.modalContent}
      centered
      show={show}
      onHide={onHide}
    >
      <Modal.Header className={styles.modalHeader} closeButton />
      <Modal.Body className={styles.modalBody}>
        {data && <ExpandingToNewStatesForm pageContent={data} />}
      </Modal.Body>
    </Modal>
  );
}
