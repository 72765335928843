import parse from "html-react-parser";
import {useState} from "react";
import styles from "./ExpandingToNewStatesForm.module.scss";
import MailchimpForm from "./MailchimpForm";

export default function ExpandingToNewStatesForm({pageContent}) {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const {
    fields: {heading, subheading, disclosure, form_success_message},
  } = pageContent;

  if (showSuccessMessage) {
    return <div className={styles.messageContent}>{form_success_message}</div>;
  }

  return (
    <div className={styles.mainContent}>
      <h1 className={styles.heading}>{heading}</h1>
      <div className={styles.subheading}>{parse(subheading)}</div>
      <MailchimpForm
        disclosure={disclosure}
        onSuccess={() => setShowSuccessMessage(true)}
      />
    </div>
  );
}
